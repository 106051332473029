'use client'

import { useState, useEffect } from 'react';

export default function CountdownTimer({ initialMinutes = 0, initialSeconds = 0, onComplete }) {
    const [[minutes, seconds], setTime] = useState([initialMinutes, initialSeconds]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (minutes === 0 && seconds === 0) {
                clearInterval(timer);
                if (onComplete) onComplete();
            } else if (seconds === 0) {
                setTime([minutes - 1, 59]);
            } else {
                setTime([minutes, seconds - 1]);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [minutes, seconds, onComplete]);

    return (
        <div className="flex items-center justify-center">
            <div className="flex items-center gap-1 text-lg font-semibold font-termina">
                <span className="!min-w-[50px] !w-[50px] !max-w-[50px] flex items-center justify-center py-2 bg-tg-background rounded-lg">
                    {String(minutes).padStart(2, '0')}
                </span>
                :
                <span className="!min-w-[50px] !w-[50px] !max-w-[50px] flex items-center justify-center py-2 bg-tg-background rounded-lg">
                    {String(seconds).padStart(2, '0')}
                </span>
            </div>
        </div>
    );
}
