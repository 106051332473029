'use client'

import {useState} from "react";
import {Modal} from "@/components/layout/modal";
import {OtpInput} from "reactjs-otp-input";
import PageTitle from "@/components/layout/page-title";
import {TbMailCheck} from "react-icons/tb";
import SimpleBtn from "@/components/inputs/simple-btn";
import {GoVerified} from "react-icons/go";
import {fetchData} from "@/helpers/general-functions";
import Cookies from "js-cookie";
import {API_URL, TG_TOKEN} from "@/helpers/variables";
import {useRouter} from "next/navigation";
import CountdownTimer from "@/components/layout/cound-down";
import SpinnerLoading from "@/components/layout/spinner-loading";

const OtpModal = ({isOpen, setIsOpen, email, isSignUpPage, forgotPass = false, setOpenNextModal = () => {}, setChangePassToken = () => {}, resend}) => {
    const router = useRouter();
    const [otp, setOtp] = useState('');
    const [otpTimeOut, setOtpTimeOut] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingResend, setLoadingResend] = useState(false);
    const [otpMin, setOtpMin] = useState(2);

    const handleChange = (otp) => setOtp(otp);

    const onResend = async (e) => {
        const one = await setLoadingResend(true);
        const tow = await resend(e);
        const three = await setLoadingResend(false);
        const four = await setOtpTimeOut(false);
        const five = await setOtpMin(2);
    }

    const handelVerification = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (otp?.length === 6 && !otpTimeOut ) {
            const verifyResponse = await fetchData(API_URL, "auth/verify/", 'post', {
                otp_code: otp,
                email: email,
                for_forget_password: forgotPass,
            }, false, {
                isHeaderJson: true,
                isLoader: false,
            });

            if (verifyResponse?.status === 200 && verifyResponse?.data?.email === email) {
                if (forgotPass) {
                    setChangePassToken(verifyResponse?.data?.token);
                    setOpenNextModal(true);
                    setIsOpen(false);
                } else {
                    await Cookies.set(TG_TOKEN, verifyResponse?.data?.token?.access);
                    setIsOpen(false);
                    if (isSignUpPage) {
                        await window.location.replace('/');
                    } else {
                        await window.location.reload();
                    }
                }
            }
        }
        setLoading(false);
    }

    return (
        <Modal isOpen={isOpen} modalClass='max-w-[700px]'>
            <div className="flex flex-col items-center gap-7 relative">
                <PageTitle
                    parentClass='xl:!mx-0 !pt-0 lg:!pt-0 xl:!pt-0 mb-5'
                    icon={<TbMailCheck />}
                    title="Check Your Email"
                    titleClass='xl:text-xl'
                    subTitle={`We have sent a verification code to ${email || 'your email.'}`}
                    subTitleClass='xl:!text-base'
                />
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    containerStyle='flex items-center gap-2'
                    inputStyle='bg-tg-background border border-tg-border focus:border-tg-text-important transition duration-150 rounded-md text-xl lg:text-2xl font-termina px-2 py-1 !w-10 lg:!w-12 outline-none focus:outline-none'
                />

                <div className="flex items-center justify-between w-full gap-4">
                    {otpTimeOut ?
                        loadingResend ?
                            (<div className="flex items-center justify-center relative !cursor-wait">
                                <SimpleBtn
                                    label='Resend Code'
                                    btnClass='bg-transparent transition duration-150 border border-tg-border'
                                />
                                <span className="absolute flex justify-center w-[calc(100%-2px)] bg-tg-bg-box !cursor-wait">
                                    <SpinnerLoading/>
                                </span>
                            </div>)
                                :
                            (<SimpleBtn
                                label='Resend Code'
                                onClick={onResend}
                                btnClass='bg-transparent hover:bg-tg-background transition duration-150 border border-tg-border'
                            />)
                            :
                            (<CountdownTimer initialMinutes={otpMin} initialSeconds={0}
                                             onComplete={() => {
                                                 setOtpTimeOut(true);
                                                 setOtpMin(0)
                                             }}/>)
                    }
                    {loading ?
                        (<div className="flex items-center justify-center relative !cursor-wait">
                            <SimpleBtn
                                label='Verify'
                                btnClass={`flex-row-reverse`}
                                icon={<GoVerified/>}
                            />
                            <span className="absolute flex w-full justify-center bg-tg-bg-btn !cursor-wait">
                                <SpinnerLoading/>
                            </span>
                        </div>)
                        :
                        (<SimpleBtn
                            label='Verify'
                            onClick={handelVerification}
                            disabled={otpTimeOut || (otp?.length < 6)}
                            btnClass={`flex-row-reverse ${(otpTimeOut || (otp?.length < 6)) ? 'cursor-not-allowed bg-tg-bg-service' : ''}`}
                            icon={<GoVerified/>}
                        />)
                    }
                </div>
            </div>
        </Modal>
    );
};

export default OtpModal;
