const FormCard = (
    {
        parentClass,
        title,
        titleClass,
        children,
        id,
    }
) => {
    return (
        <div className={`flex flex-col items-start p-4 lg:p-5 xl:p-6 bg-tg-bg-box rounded-2xl w-full gap-12 ${parentClass}`} id={id}>
            {title && (
                <span className="ps-4">
                    <h3 className={`font-bold font-termina text-xl text-tg-text-title ${titleClass}`}>
                        {title}
                    </h3>
                </span>
            )}

            {children}
        </div>
    );
};

export default FormCard;
