'use client'

import FormCard from "@/components/layout/form-card";
import SimpleInput from "@/components/inputs/simple-input";
import SimpleBtn from "@/components/inputs/simple-btn";
import {useEffect, useState} from "react";
import {fetchData} from "@/helpers/general-functions";
import OtpModal from "@/components/pages/otp-modal";
import {useSelector} from "react-redux";
import {useRouter} from "next/navigation";
import {API_URL} from "@/helpers/variables";
import ForgotPasswordModal from "@/components/pages/forgot-password-modal";
import SpinnerLoading from "@/components/layout/spinner-loading";

const FORM_FIELDS = {
    email: "",
    password: "",
}

const LoginComponent = ({ isLoginPage, parentClass, setLoginComponent = b => {} }) => {
    const router = useRouter();
    const [loginForm, setLoginForm] = useState(FORM_FIELDS);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(null);
    const [isOpenOtpModal, setOpenOtpModal] = useState(false);
    const [isOpenForgotPassModal, setOpenForgotPassModal] = useState(false);
    const isUserLoggedIn = useSelector((state) => state?.userData?.isLoggedIn);
    const loginCheckLoading = useSelector((state) => state?.userData?.loginCheckLoading);

    useEffect(() => {
        (async () => {
            if (!loginCheckLoading && isUserLoggedIn) {
                await router.push("/");
                return false;
            }
        })();
    }, [isUserLoggedIn, router])

    const changeHandler = ({target: {name, value}}) => {
        setLoginForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const loginHandler = async (e) => {
        setError(null);
        setLoading(true);
        e.preventDefault();

        const loginResponse = await fetchData(API_URL, 'auth/login/', 'post', {...loginForm}, false, {
            isHeaderJson: true,
            isLoader: false,
        }, false, false)

        if (loginResponse?.status === 200) {
            setOpenOtpModal(true);
        } else if (loginResponse?.error) {
            setError(loginResponse?.message[0]?.message)
        }
        setLoading(false);
    }


    if (!loginCheckLoading && !isUserLoggedIn) return (
        <div className={`flex flex-col gap-4 items-end lg:mx-[241px] ${parentClass} ${isLoginPage ? "mb-8" : ""}`}>
            <FormCard parentClass={!isLoginPage && '!p-0 lg:!p-0 xl:!p-0 bg-transparent'}>
                <form className="flex flex-col gap-6 w-full">
                    <SimpleInput
                        id='email'
                        changeHandler={changeHandler}
                        value={loginForm.email}
                        label='Email'
                        labelClass='font-semibold'
                        placeholder='Ex. user@example.com'
                        type="email"
                        icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16 12C16 14.209 14.209 16 12 16C9.791 16 8 14.209 8 12C8 9.791 9.791 8 12 8V8C14.209 8 16 9.791 16 12V13.5C16 14.881 17.119 16 18.5 16C19.881 16 21 14.881 21 13.5V12C21 7.029 16.971 3 12 3C7.029 3 3 7.029 3 12C3 16.971 7.029 21 12 21C13.149 21 14.317 20.782 15.444 20.315C16.052 20.063 16.614 19.747 17.133 19.386"
                                    stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>}
                    />
                    <div className="flex flex-col gap-3">
                        <SimpleInput
                            id='password'
                            changeHandler={changeHandler}
                            value={loginForm.password}
                            label='Password'
                            labelClass='font-semibold'
                            placeholder='********'
                            type="password"
                            icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M16 19V5" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>}
                        />

                        <span className='text-sm text-tg-text-link cursor-pointer' onClick={() => setOpenForgotPassModal(true)}>
                            Forgot Password?
                        </span>
                    </div>

                    <span className={`w-full text-center text-tg-bg-btn-delete transition-all duration-300 ${error ? 'text-sm mt-0' : 'text-[0px] -mt-6'}`}>
                        {error}
                    </span>
                </form>
            </FormCard>

            <div className="w-full flex flex-col sm:flex-row-reverse sm:items-center justify-between gap-3">
                {loading ?
                    (
                        <div className="w-[125px] flex items-center justify-center relative !cursor-wait">
                            <SimpleBtn
                                label='Login'
                                btnClass='flex-row-reverse self-end'
                                icon={<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5 16L14.5 12L10.5 8" stroke="white" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>}
                            />
                            <span className="absolute flex justify-center w-full bg-tg-bg-btn !cursor-wait">
                                <SpinnerLoading/>
                            </span>
                        </div>
                    ) : (
                        <SimpleBtn
                            onClick={loginHandler}
                            label='Login'
                            btnClass='flex-row-reverse self-end'
                            icon={<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.5 16L14.5 12L10.5 8" stroke="white" strokeWidth="1.5"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>}
                        />
                    )
                }

                <p className='text-sm flex flex-wrap gap-1'>
                    Don't have an account?
                    <div className='cursor-pointer' onClick={() => isLoginPage ? router.push('/signup') : setLoginComponent(false)}>
                        <span className="text-tg-text-link">
                            Sign Up
                        </span>
                    </div>
                </p>
            </div>
            <ForgotPasswordModal isOpen={isOpenForgotPassModal} setIsOpen={setOpenForgotPassModal} email={loginForm?.email} />
            <OtpModal isOpen={isOpenOtpModal} setIsOpen={setOpenOtpModal} email={loginForm?.email} isSignUpPage={isLoginPage} resend={loginHandler} />
        </div>
    );
};

export default LoginComponent;
