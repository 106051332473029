'use client'

import PageTitle from "@/components/layout/page-title";
import {Modal} from "@/components/layout/modal";
import SimpleInput from "@/components/inputs/simple-input";
import React, {useEffect, useRef, useState} from "react";
import {MdOutlineAlternateEmail} from "react-icons/md";
import SimpleBtn from "@/components/inputs/simple-btn";
import OtpModal from "@/components/pages/otp-modal";
import {changeHandler, fetchData} from "@/helpers/general-functions";
import {API_URL} from "@/helpers/variables";
import SpinnerLoading from "@/components/layout/spinner-loading";
import {isValidChangePassword} from "@/validation/auth-schemas";
import {toast} from "react-toastify";
import {gsap} from "gsap";

const ForgotPasswordModal = ({isOpen, setIsOpen, email}) => {
    const getEmailRef = useRef(null);
    const newPasswordRef = useRef(null);

    const [emailValue, setEmailValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [changePassToken, setChangePassToken] = useState(null);
    const [isOtpModalOpen, setOtpModalOpen] = useState(false);
    const [isNewPassModalOpen, setNewPassModalOpen] = useState(false);

    useEffect(() => {
        setEmailValue(email);
    }, [email]);

    const handelForgotPass = async (e) => {
        setLoading(true);
        e.preventDefault();
        e.stopPropagation();

        if (emailValue && emailValue !== '' && emailValue?.includes('@') && emailValue?.includes('.')) {
            setErrorMessage('');

            const forgotPassResponse = await fetchData(API_URL, 'auth/forget-password/', 'post', {email: emailValue}, false, {
                isHeaderJson: true,
            });

            if (forgotPassResponse?.status === 200) {
                setOtpModalOpen(true);
            }
        } else {
            setErrorMessage('Enter a valid email address');
        }
        setLoading(false);
    }

    const [loadingChange, setLoadingChange] = useState(false)
    const [errorMessages, setErrorMessages] = useState({})
    const [newPassForm, setNewPassForm] = useState({})

    useEffect(() => {
        if (isNewPassModalOpen) {
            setNewPassForm({
                password: null,
                confirm_password: null,
            })
        }
    }, [isNewPassModalOpen])

    const onChangePassword = async (e) => {
        setLoadingChange(true);
        e.preventDefault();
        e.stopPropagation();

        const validation = await isValidChangePassword(newPassForm);

        if (validation?.isValid) {
            const changePassResponse = await fetchData(API_URL, 'auth/change-password/', 'post', {
                ...newPassForm,
                token: changePassToken,
                email: emailValue,
            }, false, {
                isHeaderJson: true,
            })

            if (changePassResponse?.status === 200) {
                setIsOpen(false);
                toast.success('Your password changed successfully!');
            }
        } else {
            setErrorMessages(validation?.errors)
        }
        setLoadingChange(false)
    }

    useEffect(() => {
        if (isNewPassModalOpen) {
            // Open animation
            gsap.to(getEmailRef.current, {
                height: 0,
                opacity: 0,
                duration: 0.3,
            });
            gsap.to(newPasswordRef.current, {
                height: 'auto',
                opacity: 1,
                duration: 0.3,
                delay: 0.4,
            });
        } else {
            // Close animation
            gsap.to(newPasswordRef.current, {
                height: 0,
                opacity: 0,
                duration: 0.3,
            });
            gsap.to(getEmailRef.current, {
                height: 'auto',
                opacity: 1,
                duration: 0.3,
                delay: 0.4,
            });
        }
    }, [isNewPassModalOpen]);


    return (
        <>
            <Modal title={isNewPassModalOpen ? false : 'Forgot Password'} isOpen={isOpen} setIsOpen={setIsOpen} closeable modalClass='w-full max-w-[500px]'>
                <div ref={newPasswordRef} className="flex flex-col items-center gap-6 relative w-full overflow-hidden h-0">
                    <PageTitle
                        parentClass='xl:!mx-0 !pt-0 lg:!pt-0 xl:!pt-0 mb-5'
                        title='Set Your New Password'
                        subTitle='Password must be at least 10 characters, including uppercase and lowercase letters, numbers, and special characters.'
                        titleClass='xl:text-xl'
                        subTitleClass='!text-sm xl:!text-sm'
                    />
                    <SimpleInput
                        id='password'
                        changeHandler={(e) => changeHandler('password', e.target.value, setNewPassForm, newPassForm)}
                        value={newPassForm.password}
                        label='New Password'
                        labelClass='font-semibold'
                        placeholder='********'
                        type="password"
                        icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M16 19V5" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>}
                        errorMessage={errorMessages?.password}
                    />
                    <SimpleInput
                        id='confirm_password'
                        changeHandler={(e) => changeHandler('confirm_password', e.target.value, setNewPassForm, newPassForm)}
                        value={newPassForm.confirm_password}
                        label='Confirm New Password'
                        labelClass='font-semibold'
                        placeholder='********'
                        type="password"
                        icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.84898 11.735C7.99498 11.881 7.99498 12.119 7.84898 12.265C7.70298 12.411 7.46498 12.411 7.31898 12.265C7.17298 12.119 7.17298 11.881 7.31898 11.735C7.46498 11.588 7.70198 11.588 7.84898 11.735"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path
                                        d="M12.265 11.735C12.411 11.881 12.411 12.119 12.265 12.265C12.119 12.411 11.881 12.411 11.735 12.265C11.589 12.119 11.589 11.881 11.735 11.735C11.881 11.588 12.119 11.588 12.265 11.735"
                                        stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                        strokeLinejoin="round"/>
                                    <path d="M13 3H14C15.105 3 16 3.895 16 5C16 3.895 16.895 3 18 3H19"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M16 19V5" stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M19 21H18C16.895 21 16 20.105 16 19C16 20.105 15.105 21 14 21H13"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M13 17H5C3.895 17 3 16.105 3 15V9C3 7.895 3.895 7 5 7H13"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path d="M16 17H19C20.105 17 21 16.105 21 15V9C21 7.895 20.105 7 19 7H16"
                                          stroke="white" strokeWidth="1.5" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>}
                        errorMessage={errorMessages?.confirm_password}
                    />
                    <div className="w-full flex justify-end">
                        {loadingChange ?
                            (
                                <div className="flex items-center justify-center relative !cursor-wait">
                                    <SimpleBtn
                                        label='Set New Password'
                                    />
                                    <span className="absolute flex justify-center w-full bg-tg-bg-btn !cursor-wait">
                                        <SpinnerLoading/>
                                    </span>
                                </div>
                            ) : (
                                <SimpleBtn
                                    label='Set New Password'
                                    onClick={onChangePassword}
                                />
                            )
                        }
                    </div>
                </div>

                <div ref={getEmailRef} className="flex flex-col items-start gap-6 relative w-full overflow-hidden">
                    <PageTitle
                        parentClass='xl:!mx-0 !pt-0 lg:!pt-0 xl:!pt-0 mb-5'
                        subTitle="Confirm Your Email Address"
                        titleClass='xl:text-xl'
                        subTitleClass='!text-sm xl:!text-sm text-start'
                    />
                    <SimpleInput
                        parentClass='-mt-4'
                        id='email'
                        type='email'
                        icon={<MdOutlineAlternateEmail/>}
                        label='Email Address'
                        placeholder='Ex. user@example.com'
                        value={emailValue}
                        changeHandler={(e) => setEmailValue(e.target.value)}
                        errorMessage={errorMessage}
                    />
                    <div className="w-full flex justify-end">
                        {loading ?
                            (
                                <div className="flex items-center justify-center relative !cursor-wait">
                                    <SimpleBtn
                                        label='Confirm'
                                    />
                                    <span className="absolute flex justify-center w-full bg-tg-bg-btn !cursor-wait">
                                        <SpinnerLoading/>
                                    </span>
                                </div>
                            ) : (
                                <SimpleBtn
                                    label='Confirm'
                                    onClick={handelForgotPass}
                                />
                            )
                        }
                    </div>
                </div>
            </Modal>
            <OtpModal isOpen={isOtpModalOpen} setIsOpen={setOtpModalOpen} email={emailValue} forgotPass setOpenNextModal={setNewPassModalOpen} setChangePassToken={setChangePassToken} resend={handelForgotPass} />
        </>
    );
};

export default ForgotPasswordModal;
